@import "variables";

.wb-popup {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .4);
  z-index: 1234;

  &__content {
    background-color: #fff;
    color: $color_text;
    position: absolute;
    width: 80%;
    max-width: 800px;
    right: 0;

    &--left {
      left: 0;
    }
  }

  &--close {
    cursor: pointer;
    transition: opacity .2s linear;
    margin-top: 3rem;

    &:hover {
      opacity: .7;
    }
  }
}


