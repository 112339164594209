@import 'variables';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins/breakpoints';

.wb-form {
  .row.form-group {
    margin-bottom: 0.5rem;
  }

  input[type='email'],
  input[type='text'],
  input[type='password'],
  input[type='number'] {
    padding: 0.3rem 0.5rem;
    font-size: 1rem;
    border: 1px solid $color_lighter_gray;

    &:focus {
      outline: 5px auto $color_gray;
    }

    &:disabled {
      border: 1px solid $color_gray;
    }
  }

  input[type='email'],
  input[type='text'],
  input[type='password'],
  input[type='number'],
  label {
    width: 100%;
    color: $color_text;
  }

  label {
    padding: 0 0.5rem;
    margin: 0;
    font-size: 0.8rem;

    &:hover:before {
      border: 2px solid $color_accent !important;
    }

    &:hover {
      & + .additional-info {
        opacity: 1;
        visibility: visible;
      }
    }

    & + .additional-info {
      display: block;
      min-width: 120px;
      max-width: 200px;
      opacity: 0;
      visibility: hidden;
      position: absolute;
      bottom: 20px;
      padding: 0.6rem 1rem;
      font-size: 0.8rem;
      border-radius: 30px;
      color: $color_text;
      background: $color_light_gray;
      -webkit-box-shadow: 0 3px 3px 3px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0 3px 3px 3px rgba(0, 0, 0, 0.2);
      box-shadow: 0 3px 3px 3px rgba(0, 0, 0, 0.2);
      transition: opacity 0.3s ease-in-out;
      z-index: 123;

      @include media-breakpoint-down(lg) {
        position: static;
        opacity: 1;
        visibility: visible;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        background: #fff;
        padding: 0.5rem 0;
        width: 100%;
        max-width: 100%;
      }
    }
  }

  input[type='checkbox'] {
    &:not(:checked),
    &:checked {
      position: absolute;
      left: -9999px;

      & + label {
        position: relative;
        padding-left: 1.95rem;
        cursor: pointer;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 1.55em;
          height: 1.55em;
          border: 2px solid $color_lighter_gray;
          background: #fff;
          border-radius: 4px;
          box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
        }

        &:after {
          content: '\2713\0020';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 4px;
          font-size: 0.9rem;
          line-height: 0.7;
          color: $color_accent;
          transition: all 0.2s;
        }
      }
    }

    &:not(:checked) + label:after {
      opacity: 0;
    }

    &:checked + label:after {
      opacity: 1;
    }

    &:disabled:checked,
    &:disabled:not(:checked) {
      & + label:before {
        box-shadow: none;
        border-color: #bbb;
        background-color: #ddd;
      }
    }

    &:disabled:checked + label:after {
      color: #999;
    }

    &:disabled + label {
      color: #aaa;
    }

    &:checked:focus,
    &:not(:checked):focus {
      & + label:before {
        border: 2px dotted $color_accent;
      }
    }
  }

  .absolute {
    position: absolute;
    top: 45%;
    color: $color_text;
    background: $color_light_gray;
    right: 0;
    max-width: calc(100% - 15px);
    transform: translateY(-50%);
    padding: 0.4rem 0.8rem;
    font-size: 0.8rem;
    border-radius: 30px;
    -webkit-box-shadow: 0 3px 3px 3px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 3px 3px 3px rgba(0, 0, 0, 0.2);
    box-shadow: 0 3px 3px 3px rgba(0, 0, 0, 0.2);
  }

  .above {
    top: -50%;
  }

  input[type='radio'] {
    visibility: hidden;
    position: absolute;
  }

  .check {
    display: inline-block;
    position: relative;
    bottom: 15px;
    border: 2px solid $color_gray;
    border-radius: 100%;
    height: 26px;
    width: 26px;
    z-index: 5;
    transition: border 0.25s linear;
    -webkit-transition: border 0.25s linear;

    &::before {
      display: block;
      position: absolute;
      content: '';
      border-radius: 50%;
      height: 10px;
      width: 10px;
      top: 6px;
      left: 6px;
      transition: background 0.25s linear;
      -webkit-transition: background 0.25s linear;
    }
  }

  &__clip {
    position: relative;

    img {
      position: absolute;
      left: 10px;
      top: 8px;
    }

    input.clip-input {
      background-color: $color_lighter_gray;
      padding: 0.3rem 0.5rem 0.3rem 2.5rem;
    }
  }

  [class^='col-'],
  [class*=' col-'] {
    position: relative;
  }
}
