@import "variables";

.wb-promotions-icon-status {
  display: inline-block;
  padding: 0.6rem 2rem 0.6rem 60px;
  font-weight: bold;
  border-radius: 30px;
  position: relative;
  margin-bottom: 2rem;
  font-size: .9rem;
  color: $color_text;

  &--absolute {
    position: absolute;
    left: 15px;
    top: 0;
    z-index: 123;
  }

  &--transparent {
    background-color: rgba(255, 255, 255, .5);
  }

  &--gray {
    background-color: #F0F0F0;
  }

  img {
    position: absolute;
    left: 0;
    top: 0;
  }
}

