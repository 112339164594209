@import 'bootstrap/scss/bootstrap.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'ngx-toastr/toastr';
@import 'scss/variables';
@import 'scss/form';
@import 'scss/promotion-list';
@import 'scss/promotion-icon-status';
@import 'scss/popup';
@import 'scss/remember-section';

* {
  font-family: 'Lato', sans-serif;
}

:root {
  font-size: 16px;
}

html {
  scroll-behavior: smooth;
}

@include media-breakpoint-up(xxl) {
  .container {
    max-width: 1140px;
  }
}

.transparent-button,
.white-button,
.red-button,
.black-button {
  border-radius: 3px;
  padding: 0.6rem 2rem;
  font-size: 0.9rem;
  transition:
    background-color 0.2s linear,
    color 0.2s linear;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline: 5px auto $color_accent;
  }
}

.red-button {
  background-color: $color_accent;
  color: #fff;
  border: 2px solid $color_accent;

  &:hover {
    background-color: #fff;
    color: $color_accent;
  }
}

.empty-button {
  background-color: transparent;
  color: $color-text;
  transition: opacity 0.2s linear;
  border: none;

  &:hover {
    opacity: 0.7;
  }
}

.transparent-button {
  background-color: transparent;
  color: #000;
  border: 2px solid #000;

  &:hover {
    background-color: #000;
    color: #fff;
  }
}

.white-button {
  background-color: #fff;
  color: #000;
  border: 2px solid #000;

  &:hover {
    background-color: #000;
    color: #fff;
  }
}

.black-button {
  background-color: #000;
  color: #fff;
  border: 2px solid #000;

  &:hover {
    background-color: #fff;
    color: #000;
    border: 2px solid $color_accent;
  }
}

.accent {
  color: $color_accent;
}

.gray {
  color: $color_gray;
}

.color-text {
  color: $color_text;
}

a.accent {
  text-decoration: underline;
  transition: opacity 0.2s linear;

  &:active,
  &:focus,
  &:hover {
    color: $color_accent;
    opacity: 0.7;
  }
}

.fixed-wrap-nav {
  z-index: 1050;
  padding: 6rem 5rem 0 6rem;
  background-color: #fff;
  top: 70px;
  bottom: 0;
  right: 0;
  position: fixed;
  transform: translateX(110%);
  -webkit-box-shadow: -5px 0px 5px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: -5px 0px 5px 0px rgba(0, 0, 0, 0.3);
  box-shadow: -5px 0px 5px 0px rgba(0, 0, 0, 0.3);

  @include media-breakpoint-down(xxl) {
    padding: 4rem 2rem 0 3rem;
  }

  @include media-breakpoint-down(xl) {
    padding: 2rem 1rem 0 2rem;
  }
}

.wb-breadcrumbs {
  a {
    color: $color_text;
    transition: opacity 0.2s ease-in-out;

    &:hover {
      opacity: 0.7;
    }
  }
}

@media screen and (max-width: 999px) {
  .fixed-wrap-nav {
    top: 134px;
  }
}

// first toast doesn't appear
.ngx-toastr {
  display: block !important;
  opacity: 1 !important;
}

router-outlet + * {
  display: block;
  animation: fade 1s;

  @keyframes fade {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}

.custom-checkbox {
  display: inline-block !important;
  cursor: pointer;

  label {
    cursor: pointer;
  }
}

input.custom-control-input[type='checkbox']:checked ~ .custom-control-label::after {
  top: 0.1rem !important;
  left: -1.1rem !important;
}

nb-checkbox label {
  cursor: pointer;
}

.custom-checkbox {
  display: inline-block !important;
  cursor: pointer;

  label {
    cursor: pointer;
  }
}

input.custom-control-input[type='checkbox']:checked ~ .custom-control-label::after {
  top: 0.1rem !important;
  left: -1.1rem !important;
}

nb-checkbox label {
  cursor: pointer;
}
