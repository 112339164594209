@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins/breakpoints';

.promotions-list {
  &__row {
    padding-bottom: 8rem;

    @include media-breakpoint-down(lg) {
      padding-bottom: 2rem;
    }

    &__description {
      font-size: 1.05rem;
    }
  }

  &--image {
    height: 280px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &--logo {
    width: 150px;
    margin-bottom: 1rem;

    @include media-breakpoint-down(lg) {
      margin: 1rem auto;
      display: block;
    }
  }
}
