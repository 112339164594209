$color_accent: #B30000;
$color_red: #c30000;

$color_text: #414140;

$color_gray: #3F3F3F;
$color_light_gray: #E1E1E1;
$color_lighter_gray: #F1F1F1;




