.wb-remember-section {

  p {
    font-size: 1.15rem;
    line-height: 1.6;
    font-weight: bold;
  }

}

